import request from '@/utils/request'
// 出口订单报文
const requestApi = {
  e_ceb603_import_list: '/e/ceb603/import/',
  e_ceb603_import_excel_list: '/e/ceb603/import/excel/',
  e_ceb603_import_excel_create: '/e/ceb603/import/excel/',
  e_ceb603_import_partial_update: '/e/ceb603/import/',
  e_ceb603_import_delete: '/e/ceb603/import/',
  e_ceb603_inventory_list: '/e/ceb603/inventory/',
  e_ceb603_order_status_list: '/e/ceb603/order/',
  e_ceb603_inventory_partial_update: '/e/ceb603/inventory/',
  e_ceb603_inventory_delete: '/e/ceb603/inventory/'
}

export function e_ceb603_import_list (query) {
  return request({
    url: requestApi.e_ceb603_import_list,
    method: 'get',
    params: query
  })
}
export function e_ceb603_order_status_list (query, id) {
  return request({
    url: requestApi.e_ceb603_order_status_list + id + '/status/',
    method: 'get',
    params: query
  })
}
export function e_ceb603_inventory_list (query) {
  return request({
    url: requestApi.e_ceb603_inventory_list,
    method: 'get',
    params: query
  })
}
export function e_ceb603_import_excel_list (query) {
  return request({
    url: requestApi.e_ceb603_import_excel_list,
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}
/**
 * 删除
 */
export function e_ceb603_import_delete (id) {
  return request({
    url: requestApi.e_ceb603_import_delete + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 删除
 */
export function e_ceb603_inventory_delete (id) {
  return request({
    url: requestApi.e_ceb603_inventory_delete + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function e_ceb603_import_excel_create (data) {
  return request({
    url: requestApi.e_ceb603_import_excel_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function e_ceb603_import_partial_update (data, id) {
  return request({
    url: requestApi.e_ceb603_import_partial_update + id + '/',
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}
export function e_ceb603_inventory_partial_update (data, id) {
  return request({
    url: requestApi.e_ceb603_inventory_partial_update + id + '/',
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}
