<template>
  <div>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :scroll="{x:5200}"
      :show-pagination="false"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <span>
            <a @click="handleUpdate(record)" v-if="!record.is_pushed">推送</a>
            <a @click="handleUpdate(record)" v-if="record.is_pushed">重推</a>
            <a-divider type="vertical"/>
            <a @click="handleGoods(record)">商品</a>
            <a-divider type="vertical"/>
            <a @click="handleRecordList(record)">状态记录</a>
            <a-divider type="vertical"/>
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelet(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </span>
        </template>
      </span>
    </s-table>
    <a-modal
      v-model="goodsVisible"
      title="商品详情"
      width="800px"
      :footer="null"
      v-if="goodsVisible"
    >
      <a-table
        size="middle"
        :columns="innerColumns"
        :data-source="innerData"
        :pagination="false"
      >
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import {
  e_ceb603_inventory_delete,
  e_ceb603_inventory_list,
  e_ceb603_inventory_partial_update
} from '@/api/ceb603'

export default {
  name: 'OrderReadList',
  components: {
    STable
  },
  props: {},
  data () {
    return {
      goodsVisible: false,
      expandedRowKeys: ['id'],
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单号',
          dataIndex: 'order_no',
          fixed: 'left',
          width: 200
        },
        {
          title: '报文编号',
          width: 400,
          dataIndex: 'message_guid'
        },
        {
          title: '订单编号',
          width: 400,
          dataIndex: 'guid'
        },
        {
          title: '毛重(公斤)',
          width: 100,
          dataIndex: 'gross_weight'
        },
        {
          title: '电商企业代码',
          dataIndex: 'ebc_code'
        },
        {
          title: '申报企业名称',
          dataIndex: 'agent_name',
          ellipsis: true
        },
        {
          title: '生产销售企业代码',
          dataIndex: 'owner_code',
          width: 180
        },
        {
          title: '申报企业代码',
          dataIndex: 'agent_code',
          width: 180
        },
        {
          title: '监管场所代码',
          dataIndex: 'loct_no',
          customRender: (text) => text || '无',
          width: 150
        },
        {
          title: '运杂费',
          width: 100,
          dataIndex: 'freight'
        },
        {
          title: '电子口岸编号',
          width: 100,
          dataIndex: 'pre_no'
        },
        {
          title: '申报业务类型',
          width: 100,
          dataIndex: 'statistics_flag'
        },
        {
          title: '贸易方式',
          width: 100,
          dataIndex: 'trade_mode'
        },
        {
          title: '包装种类代码',
          width: 100,
          dataIndex: 'wrap_type'
        },
        {
          title: '件数',
          width: 100,
          dataIndex: 'pack_no'
        },
        {
          title: '出口口岸代码',
          width: 100,
          dataIndex: 'port_code'
        },
        {
          title: '申报地海关代码',
          width: 130,
          dataIndex: 'customs_code'
        },
        {
          title: '运输工具',
          align: 'center',
          dataIndex: 'traf_name'
        },
        {
          title: '区内企业代码',
          align: 'center',
          dataIndex: 'iac_code'
        },
        {
          title: '区内企业名称',
          align: 'center',
          dataIndex: 'iac_name'
        },
        {
          title: '清单编号',
          align: 'center',
          dataIndex: 'invt_no'
        },
        {
          title: '许可证号',
          align: 'center',
          dataIndex: 'license_no'
        },
        {
          title: '物流企业代码',
          dataIndex: 'logistics_code',
          width: 180
        },
        {
          title: '物流运单编号',
          dataIndex: 'logistics_no',
          width: 200
        },
        {
          title: '运费货币',
          align: 'center',
          dataIndex: 'f_currency'
        },
        {
          title: '运费标志',
          align: 'center',
          dataIndex: 'f_flag'
        },
        {
          title: '航班航次号',
          align: 'center',
          dataIndex: 'voyage_no',
          customRender: (text) => !text ? '-' : text
        },
        {
          title: '申请类型',
          align: 'center',
          dataIndex: 'app_type'
        },
        {
          title: '电商平台代码',
          dataIndex: 'ebp_code'
        },
        {
          title: '电商企业名称',
          dataIndex: 'ebc_name'
        },
        {
          title: '是否推送',
          width: 100,
          dataIndex: 'is_pushed',
          customRender: (text) => text === false ? '未推送' : '已推送'
        },
        {
          title: '报送时间',
          width: 200,
          dataIndex: 'app_time',
          customRender: (text) => text === '1970-01-01' ? '无' : text
        },
        {
          title: '备注',
          dataIndex: 'note'
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          width: '300',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      innerColumns: [
        {
          title: '商品序号',
          key: 'gnum',
          dataIndex: 'gnum'
        },
        {
          title: '名称',
          dataIndex: 'item_name'
        },
        {
          title: '价格',
          dataIndex: 'price'
        },
        {
          title: '总价值',
          dataIndex: 'total_price'
        },
        {
          title: '货币',
          dataIndex: 'currency'
        },
        {
          title: '编码',
          dataIndex: 'barcode'
        },
        {
          title: '单位',
          dataIndex: 'unit'
        },
        {
          title: '申报数量',
          dataIndex: 'qty'
        }
      ],
      innerData: [],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', this.$route.query.batch)
        return e_ceb603_inventory_list(Object.assign({ batch: this.$route.query.batch }, parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            res.data.entries.forEach(item => {
              item.goods.forEach(item => {
                item.id = item.id + 'son'
              })
            })
            return res.data
          })
      }
    }
  },
  created () {},
  methods: {
    handleRecordList (record) {
      console.log(record)
      // this.$emit('onStatus', record)
      this.$router.push({ path: '/e/customs_order/export/inventory/' + record.order_no + '/records' })
    },
    handleGoods (record) {
      console.log(record.goods, 12399)
      this.innerData = record.goods
      this.goodsVisible = true
    },
    handleUpdate (record) {
      console.log(record)
      e_ceb603_inventory_partial_update(record.is_pushed ? { force: true } : {}, record.id).then((res) => {
        this.$refs.table.refresh()
      })
        .catch((err) => {
          console.log(err)
          this.$refs.table.refresh()
        })
    },
    handleDelet (record) {
      e_ceb603_inventory_delete(record.id).then((res) => {
        this.$refs.table.refresh()
      })
        .catch((err) => {
          this.$message.error('网络出错,请联系管理员' + err)
        })
    }
  }
}
</script>
